<mat-toolbar class="shadow-lg row justify-start items-center z-10" style="padding-right: 0 !important" color="primary">
  <button class="flex items-center justify-center" (click)="toggleSidebar()">
    <mat-icon>&#xe5d2;</mat-icon>
  </button>
  <span>
    <span id="ts-heading" class="hidden sm:inline-block">
      <!--<button style="position: relative;top: -3px;margin-right: 0!important;" mat-button [routerLink]="['/']">
        <img src="assets/img/tanzspass_wordmark_52.png" alt="">
      </button>-->
    </span>
    <button [routerLink]="['/']" class="p-2 ml-0 hover:bg-orangeHover duration-100">
      <div class="flex">
        <img style="height: 52px; width: 99px" width="99" height="52" src="assets/img/tanzspass_wordmark.svg"
          alt="Tanzspass.com-Logo | Tanzen im Hamburger Westen" />&nbsp;
        <img style="height: 47px; width: 91px" [src]="school?.getLogoPath()" [alt]="school?.name" />
      </div>
    </button>
  </span>
  <span class="flex-grow"></span>
  <ts-course-toolbar-menu class="hidden sm:block"></ts-course-toolbar-menu>
  <span class="flex items-center space-x-2 mr-2 lg:mr-0">
    <a [href]="school?.voucher_url" target="_blank"
      class="hidden sm:flex hover:bg-orangeHover duration-100 rounded-full px-3 py-1.5 text-sm items-center space-x-1">
      <mat-icon>&#xe8f6;</mat-icon>
      <div>Gutscheine</div>
    </a>
    <div>
      <button
        class="bg-white text-orange shadow-md duration-100 rounded-full px-3 py-1.5 text-sm items-center space-x-1 hidden sm:flex"
        [matMenuTriggerFor]="customerMenu">
        <mat-icon>&#xe420;</mat-icon>
        <div>Kundenbereich</div>
      </button>
    </div>

    <mat-menu #customerMenu="matMenu" class="rounded-full">
      <button [routerLink]="['kundenbereich', 'videos']" mat-menu-item>
        <mat-icon>&#xe02c;</mat-icon>
        Mediathek
      </button>
      <button *ngIf="school?.has_gallery" mat-menu-item [routerLink]="['/kundenbereich', 'galerie']">
        <mat-icon>&#xe410;</mat-icon>
        Fotogalerie
      </button>
      <button mat-menu-item [routerLink]="['/kundenbereich', 'kursprogramm']">
        <mat-icon>&#xe415;</mat-icon>
        Kursprogramm
      </button>
      <button mat-menu-item [routerLink]="['/kundenbereich', 'info']">
        <mat-icon>info</mat-icon>
        Aktuelle Info
      </button>
      @if (['leseberg', 'higle'].includes(school.short_name)) {
      <a mat-menu-item [href]="'https://' + school.short_name + '.plussengine.de/Kursuebersicht?action=reservation'"
        target="_blank">
        <mat-icon>person</mat-icon>
        Registrierungsportal
      </a>
      } @else if (school.short_name === 'riemer') {
      <a mat-menu-item href="https://riemer.meinpluss.de/app"
        target="_blank">
        <mat-icon>person</mat-icon>
        Registrierungsportal
      </a>
      }
    </mat-menu>
  </span>
  <ts-school-links-toolbar class="hidden lg:block ml-2"></ts-school-links-toolbar>
  <button class="flex items-center justify-center mr-2" *ngIf="isAuthenticated" [matMenuTriggerFor]="backendMenu">
    <mat-icon>settings</mat-icon>
  </button>
  <mat-menu #backendMenu="matMenu">
    <button mat-menu-item [routerLink]="['/backend']">
      <mat-icon>settings</mat-icon>
      Backend
    </button>
    <!--  <button mat-menu-item [routerLink]="['/backend/attributes']">
      <mat-icon>settings</mat-icon>
      Attribute
    </button> -->
    <button mat-menu-item (click)="onLogout()" [routerLink]="['/login']">
      <mat-icon>exit_to_app</mat-icon>
      Abmelden
    </button>
  </mat-menu>
</mat-toolbar>
