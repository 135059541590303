import { isPlatformBrowser, NgIf } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { RouterLink } from '@angular/router';
import { News } from '../../models/news';
import { School } from '../../models/school';
import { NewsService } from '../../services/news.service';
import { SchoolService } from '../../services/school.service';
import { PictureComponent } from '../../shared/picture/picture.component';

@Component({
    selector: 'ts-news-header',
    templateUrl: './news-header.component.html',
    styleUrls: ['./news-header.component.css'],
    standalone: true,
    host: {ngSkipHydration: 'true'},
    imports: [NgIf, PictureComponent, RouterLink]
})
export class NewsHeaderComponent implements OnInit {

  public school: School = new School();
  public news: News[] = new Array();
  public currentNews: News = new News();
  private newsCounter = 0;

  constructor(
    private schoolSrv: SchoolService,
    private newsSrv: NewsService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  private nextNews() {
    this.currentNews = this.news[this.newsCounter];
    this.newsCounter = this.newsCounter < this.news.length - 1
      ? this.newsCounter + 1
      : 0;
  }

  ngOnInit() {
    this.schoolSrv.current().subscribe(school => {
      this.school = school;
      this.newsSrv.bySchoolId(this.school.id).subscribe(news => {
        this.news = news;
        this.nextNews();
        if (isPlatformBrowser(this.platformId)) {
          setInterval(this.nextNews.bind(this), 10000);
        }
      });
    });
  }
}
